import React, { useMemo } from "react";
import { GoogleMap, Marker } from "@react-google-maps/api";
import { LatLng } from "use-places-autocomplete";

type Props = {
  location: LatLng & { address: string };
  zoomLevel?: number;
};

export default function MapComponent({ location, zoomLevel = 12 }: Props) {
  const currentLocation = useMemo(
    () => ({ lat: location.lat, lng: location.lng }),
    [location]
  );

  return (
    <GoogleMap
      center={currentLocation as any}
      zoom={zoomLevel}
      mapContainerStyle={{ height: "50vh", width: "100%", borderRadius: 8 }}
    >
      {currentLocation && <Marker position={currentLocation} />}
    </GoogleMap>
  );
}
