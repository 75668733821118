import React from "react";
import { Auth } from "aws-amplify";
import { ItemType } from "antd/lib/menu/hooks/useItems";
import { Divider, Menu, Rate, Typography, Skeleton } from "antd";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useRestaurantContext } from "../../context/restaurant-context";
import LanguageSelector from "../language-selector";

function RestaurantInfo() {
  const { restaurant, authUser } = useRestaurantContext();


  return (
    <div style={{ display: "flex", flexDirection: "column", padding: 16 }}>
      <Divider />

      <Typography.Text strong>{"Restaurant-Info"}</Typography.Text>
      <Typography.Text>{restaurant?.name}</Typography.Text>
      <Typography.Text>{restaurant?.address}</Typography.Text>
      <Typography.Text
        copyable
        style={{ whiteSpace: "nowrap" }}
        ellipsis={true}
      >
        ID: {restaurant?.id}
      </Typography.Text>
      <Rate allowHalf disabled defaultValue={restaurant?.rating || 0} />

      <Divider />

      <div style={{ display: "flex", flexDirection: "column" }}>
        <Typography.Text strong>{"User-Info"}</Typography.Text>
        <Typography.Text>{authUser?.attributes?.email}</Typography.Text>

        <Typography.Text
          copyable
          style={{ whiteSpace: "nowrap" }}
          ellipsis={true}
        >
          ID: {authUser?.attributes?.sub}
        </Typography.Text>
      </div>

      <Divider />
    </div>
  );
}

export default function SideMenu() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const restaurantContext = useRestaurantContext();
  const mainMenuItems: ItemType[] = [
    { key: "/", label: t("app.side-menu.orders") },
    { key: "menu", label: t("app.side-menu.menu") },
    { key: "order-history", label: t("app.side-menu.order-history") },
  ];

  const menuItems: ItemType[] = [
    ...(restaurantContext.restaurant ? mainMenuItems : []),
    { key: "settings", label: t("app.side-menu.settings") },
    { key: "signOut", label: t("app.side-menu.sign-out"), danger: true },
  ];

  const onClickMenuItem = async (menuItem: ItemType) => {
    if (menuItem?.key === "signOut") {
      await Auth.signOut();
      // window.location.reload();
    } else {
      navigate(`${menuItem?.key}`);
    }
  };

  return (
    <>
      <Divider />

      <Menu items={menuItems} onClick={onClickMenuItem} />

      <RestaurantInfo />

      <LanguageSelector />
    </>
  );
}
