import React, { useEffect, useState } from "react";
import { Checkbox, Form, Col, Row } from "antd";
import type { CheckboxOptionType } from "antd";
import { useTranslation } from "react-i18next";

import { DishCategories } from "../../models";

const DishCategoriesComponent: React.FC = () => {
  const { t } = useTranslation();
  const [options, setOptions] = useState<CheckboxOptionType[]>([]);

  useEffect(() => {
    function enumToArray(): CheckboxOptionType[] {
      return Object.keys(DishCategories).map((key) => ({
        label:
          // @ts-ignore
          DishCategories[key].charAt(0) +
          // @ts-ignore
          DishCategories[key].substring(1).toLowerCase(),
        value: key,
      }));
    }

    const data = enumToArray();

    setOptions(
      data.map((d) => {
        return {
          ...d,
          label: t(`app.categories.dish.${(d.label as string).toLowerCase()}`),
        };
      })
    );
  }, []);

  return (
    <Form.Item
      label={t("app.create-menu-item.label.food-categories")}
      name={"foodCategories"}
      rules={[{ required: true }]}
      required
    >
      <Checkbox.Group>
        <Col>
          {options.map((option, index) => {
            return (
              <Row key={index.toString()}>
                <Checkbox value={option.value}>{option.label}</Checkbox>
              </Row>
            );
          })}
        </Col>
      </Checkbox.Group>
    </Form.Item>
  );
};

export default DishCategoriesComponent;
