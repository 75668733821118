import { Button, Card } from "antd";
import React from "react";

export default function ErrorFallback({
  error,
  resetErrorBoundary,
}: {
  error: Error;
  resetErrorBoundary: () => void;
}) {
  return (
    <Card>
      <div role="alert">
        <h3>Something went wrong:</h3>
        <pre>{error.message}</pre>
        <Button onClick={resetErrorBoundary}>Try again</Button>
      </div>
    </Card>
  );
}
