/*
 * Copyright 2017-2017 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License"). You may not use this file except in compliance with
 * the License. A copy of the License is located at
 *
 *     http://aws.amazon.com/apache2.0/
 *
 * or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR
 * CONDITIONS OF ANY KIND, either express or implied. See the License for the specific language governing permissions
 * and limitations under the License.
 */

import React, { Component, CSSProperties } from "react";

import { Logger } from "aws-amplify";
import { ArrowRightOutlined } from "@ant-design/icons";
import AmplifyTheme from "./AmplifyTheme";

import Picker from "./Picker";
import S3Image from "./S3Image";
import { ImagePickData } from "./screens/settings";

const Container = {};

const PickerPreview = {
  maxWidth: "100%",
};

const logger = new Logger("PhotoPicker");

type Props = {
  preview?: boolean;
  onPick: (data: ImagePickData) => void;
  onLoad: (url: string | ArrayBuffer) => void;
  title: string;
  theme?: typeof AmplifyTheme;
  previewSrc: string | ArrayBuffer | undefined;
  containerStyle: CSSProperties;
  previewStyle: CSSProperties;
  s3Image?: string | null;
};

type State = {
  previewSrc: string | ArrayBuffer;
};

export default class PhotoPicker extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.handlePick = this.handlePick.bind(this);

    this.state = {
      previewSrc: props.previewSrc || "",
    };
  }

  handlePick(data: ImagePickData) {
    var that = this;
    const { file, name, size, type } = data;
    const { preview, onPick, onLoad } = this.props;

    if (onPick) {
      onPick(data);
    }

    if (preview) {
      const reader = new FileReader();
      reader.onload = function (e) {
        const url = e.target!.result!;
        that.setState({ previewSrc: url });
        if (onLoad) {
          onLoad(url);
        }
      };
      reader.readAsDataURL(file as any);
    }
  }

  render() {
    const { previewSrc } = this.state;

    const title = this.props.title || "Pick a Photo";

    const theme = this.props.theme || AmplifyTheme;
    const containerStyle = Object.assign(
      {},
      Container,
      this.props.containerStyle
    );
    const previewStyle = Object.assign(
      {},
      PickerPreview,
      this.props.previewStyle
    );

    return (
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div style={containerStyle}>
          {this.props.s3Image ? (
            <S3Image imgKey={this.props.s3Image} style={previewStyle} />
          ) : (
            <div style={{ backgroundColor: "lightgrey", ...previewStyle }} />
          )}
          <Picker
            title={title}
            accept="image/*"
            theme={theme}
            onPick={this.handlePick}
          />
        </div>

        {previewSrc && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginBottom: 40,
            }}
          >
            <div style={{}}>
              <ArrowRightOutlined style={{ fontSize: 24 }} />
            </div>

            <img src={previewSrc as string} style={previewStyle} />
          </div>
        )}
      </div>
    );
  }
}
