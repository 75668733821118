import React from "react";
import { Button, Card, Form, Input, InputNumber, message } from "antd";
import { DataStore } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import DishCategoriesComponent from "../../components/dish-categories";
import { Dish, DishCategories } from "../../models";
import { useRestaurantContext } from "../../context/restaurant-context";

type FormValues = {
  name: string;
  description: string;
  price: number;
  dishCategories: DishCategories;
};

export default function CreateMenuItem() {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const { restaurant } = useRestaurantContext();

  const onFinish = async (values: FormValues) => {
    console.log({ values });
    if (!restaurant) {
      return message.error(t("app.messages.error.no-restaurant-id"));
    }

    // TODO: ADD FOOD CATEGORIES AFTER DEPLOYMENT

    await DataStore.save(
      new Dish({
        name: values.name,
        description: values.description,
        price: values.price,
        restaurantID: restaurant.id,
        dishCategories: values.dishCategories,
      })
    );

    message.success(t("app.messages.success.dish-created"));
    navigate(-1);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.error("onFinishFailed", errorInfo);
  };

  return (
    <Card title={t("app.create-menu-item.title")}>
      <Form
        layout={"vertical"}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          label={t("app.create-menu-item.label.dish-name")}
          name={"name"}
          rules={[{ required: true }]}
          required
        >
          <Input
            placeholder={t("app.create-menu-item.placeholder.dish-name") || ""}
          />
        </Form.Item>

        <Form.Item
          label={t("app.create-menu-item.label.dish-description")}
          name={"description"}
          rules={[{ required: true }]}
          required
        >
          <Input.TextArea
            rows={4}
            placeholder={
              t("app.create-menu-item.placeholder.dish-description") || ""
            }
          />
        </Form.Item>

        <DishCategoriesComponent />

        <Form.Item
          label={t("app.create-menu-item.label.price") + " (€)"}
          name={"price"}
          rules={[{ required: true }]}
          required
        >
          <InputNumber />
        </Form.Item>

        <Form.Item>
          <Button type={"primary"} htmlType={"submit"}>
            {t("app.create-menu-item.button-submit")}
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
}
