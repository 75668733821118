import { useEffect, useState } from "react";
import { Button, Card, Popconfirm, Table } from "antd";
import { Link } from "react-router-dom";
import { DataStore } from "aws-amplify";
import { useTranslation } from "react-i18next";

import { Dish } from "../../models";
import { useRestaurantContext } from "../../context/restaurant-context";

export default function RestaurantMenu() {
  const { t } = useTranslation();

  const [dishes, setDishes] = useState<Dish[]>([]);
  const { restaurant } = useRestaurantContext();

  useEffect(() => {
    if (!restaurant) {
      return;
    }

    DataStore.query(Dish, (d) => d.restaurantID.eq(restaurant.id)).then(
      setDishes
    );
  }, [restaurant, restaurant?.id]);

  const onConfirmDeleteDish = async (dish: Dish) => {
    await DataStore.delete(dish);
    setDishes(dishes.filter((d) => d.id !== dish.id));
  };

  const tableColumns = [
    {
      title: t("app.menu-item.table.menu-item"),
      dataIndex: "name",
      name: "name",
    },
    {
      title: t("app.menu-item.table.price"),
      dataIndex: "price",
      name: "price",
      render: (price: number) => `${price} €`,
    },
    {
      title: t("app.menu-item.table.action"),
      dataIndex: "action",
      name: "action",
      render: (_: any, data: Dish) => (
        <Popconfirm
          placement={"topLeft"}
          title={t("app.menu-item.pop-alert.title")}
          onConfirm={() => onConfirmDeleteDish(data)}
          okText={t("app.menu-item.pop-alert.yes")}
          cancelText={t("app.menu-item.pop-alert.no")}
        >
          <Button danger>{t("app.menu-item.button-remove")}</Button>
        </Popconfirm>
      ),
    },
  ];

  const renderNewItemButton = () => {
    return (
      <Link to={"create"}>
        <Button type={"primary"}>{t("app.menu-item.button-new-item")}</Button>
      </Link>
    );
  };

  return (
    <Card title={"Menu"} extra={renderNewItemButton()}>
      <Table dataSource={dishes} columns={tableColumns} rowKey={"id"} />
    </Card>
  );
}
