import React, { useEffect } from "react";
import { Card, Table } from "antd";
import { useNavigate } from "react-router-dom";
import { DataStore } from "aws-amplify";
import moment from "moment";

import renderOrderStatus from "../../utils/renderOrderStatus";
import { Order, OrderStatus } from "../../models";
import { useRestaurantContext } from "../../context/restaurant-context";
import { useTranslation } from "react-i18next";

export default function OrderHistory() {
  const { t } = useTranslation();
  const [orderHistory, setOrderHistory] = React.useState<Order[]>([]);
  const { restaurant } = useRestaurantContext();

  const navigate = useNavigate();

  useEffect(() => {
    if (!restaurant) {
      return;
    }

    /**
     * ONLY DISPLAY NEW, COOKING AND READY_FOR_PICKUP ORDERS
     */
    DataStore.query(Order, (order) =>
      order.and((order) => [
        order.orderRestaurantId.eq(restaurant.id),
        order.or((o) => [
          o.status.eq(OrderStatus.COMPLETED),
          o.status.eq(OrderStatus.DECLINED_BY_RESTAURANT),
          o.status.eq(OrderStatus.PICKED_UP),
        ]),
      ])
    ).then((data) => {
      const sortedByCreationDate = data
        .sort(
          // @ts-ignore
          (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
        )
        .reverse();
      setOrderHistory(sortedByCreationDate || data);
    });
  }, [restaurant]);

  const tableColumns = [
    { title: t("app.order-history.order-id"), dataIndex: "id", key: "id" },
    {
      title: t("app.order-history.created-at"),
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt: string) => moment(createdAt).fromNow(),
    },
    {
      title: t("app.order-history.price"),
      dataIndex: "total",
      key: "total",
      render: (price: number) => `${price} €`,
    },
    {
      title: t("app.order-history.status"),
      dataIndex: "status",
      key: "status",
      render: renderOrderStatus,
    },
  ];

  const onClickedOnRow = (orderItem: Order) => {
    console.log(orderItem);
    navigate(`/order/${orderItem.id}`);
  };

  return (
    <Card title={t("app.order-history.title")}>
      <Table
        dataSource={orderHistory}
        columns={tableColumns}
        rowKey={"id"}
        onRow={(orderItem) => ({
          onClick: () => onClickedOnRow(orderItem),
        })}
      />
    </Card>
  );
}
