import React, { useEffect, useState } from "react";
import { Button, Card, Descriptions, Divider, List, Spin } from "antd";
import { useParams } from "react-router-dom";
import { DataStore } from "aws-amplify";

import LoadingOverlay from "../../components/loading-overlay";
import renderOrderStatus from "../../utils/renderOrderStatus";
import { Dish, Order, OrderDish, OrderStatus, User } from "../../models";
import { StylesInterface } from "../../types/styles.interface";
import { useTranslation } from "react-i18next";

function DishItem({ dishItem }: { dishItem: OrderDish }) {
  const [theDish, setTheDish] = useState<Dish>();

  useEffect(() => {
    dishItem?.Dish?.then(setTheDish);
  }, [dishItem?.Dish]);

  return (
    <List.Item key={dishItem.id}>
      <div style={{ fontWeight: "bold" }}>
        {theDish?.name} x{dishItem.quantity}
      </div>
      <div>
        {theDish?.price}
        {" €"}
      </div>
    </List.Item>
  );
}

export default function DetailedOrder() {
  const { id } = useParams();
  const { t } = useTranslation();

  const [loading, setLoading] = React.useState<boolean>(false);

  const [order, setOrder] = React.useState<Order>();
  const [customer, setCustomer] = React.useState<User>();
  const [dishes, setDishes] = React.useState<OrderDish[]>([]);

  useEffect(() => {
    if (!id) {
      return;
    }

    DataStore.query(Order, id).then(setOrder);
  }, [id]);

  /**
   * Fetching User
   */
  useEffect(() => {
    if (!order?.userID) {
      return;
    }

    DataStore.query(User, order?.userID).then(setCustomer);
  }, [order?.userID]);

  /**
   * Fetching OrderDish
   */
  useEffect(() => {
    if (!order?.id) {
      return;
    }

    DataStore.query(OrderDish, (o) => o.orderID.eq(order.id)).then(setDishes);
  }, [order?.id]);

  const onDeclineOrder = async () => {
    await updateOrderStatus(OrderStatus.DECLINED_BY_RESTAURANT);
  };
  const onAcceptOrder = async () => {
    await updateOrderStatus(OrderStatus.COOKING);
  };
  const onReadyForPickup = async () => {
    await updateOrderStatus(OrderStatus.READY_FOR_PICKUP);
  };
  const onResetReadyForPickup = async () => {
    await updateOrderStatus(OrderStatus.NEW);
  };

  const updateOrderStatus = async (newStatus: OrderStatus) => {
    setLoading(true);
    try {
      const updatedOrder = await DataStore.save(
        Order.copyOf(order!, (updated) => {
          updated.status = newStatus;
        })
      );
      setOrder(updatedOrder);
    } catch (error) {
      console.error("ERROR | updateOrderStatus", error);
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
  };

  if (!order) {
    return <LoadingOverlay />;
  }

  return (
    <Card title={t("app.order-detail.title") + " " + id}>
      {order?.status && renderOrderStatus(order.status)}
      <Descriptions bordered column={{ lg: 1, md: 1, sm: 1 }}>
        <Descriptions.Item label={t("app.order-detail.customer")}>
          {customer?.name}
        </Descriptions.Item>
        <Descriptions.Item label={t("app.order-detail.address")}>
          {customer?.address}
        </Descriptions.Item>
      </Descriptions>

      <Divider />

      <List dataSource={dishes} renderItem={(d) => <DishItem dishItem={d} />} />

      <Divider />

      <div style={styles.totalSumContainer}>
        <h2>Total: </h2>
        <h4 style={styles.totalPrice}>
          {order?.total} {"€"}
        </h4>
      </div>

      <Divider />

      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Spin />
        </div>
      ) : (
        <>
          {order.status === OrderStatus.NEW && (
            <div style={styles.buttonsContainer}>
              <Button
                block
                danger
                size={"large"}
                style={styles.button}
                onClick={onDeclineOrder}
              >
                Decline Order
              </Button>
              <Button
                block
                type={"primary"}
                size={"large"}
                style={styles.button}
                onClick={onAcceptOrder}
              >
                Accept Order
              </Button>
            </div>
          )}

          {order.status === OrderStatus.COOKING && (
            <div style={styles.buttonsContainer}>
              <Button
                block
                type={"primary"}
                size={"large"}
                style={styles.button}
                onClick={onReadyForPickup}
              >
                Food is Done
              </Button>
            </div>
          )}

          <div style={styles.buttonsContainer}>
            <Button
              block
              size={"large"}
              style={styles.button}
              onClick={onResetReadyForPickup}
            >
              Reset (DEV)
            </Button>
          </div>
        </>
      )}
    </Card>
  );
}

const styles: StylesInterface = {
  totalSumContainer: {
    flexDirection: "row",
    display: "flex",
  },
  totalPrice: {
    marginLeft: "auto",
    fontWeight: "bold",
  },
  buttonsContainer: {
    display: "flex",
  },
  button: {
    margin: 8,
  },
};
