import React, { useEffect, useState } from "react";
import { Button, Card, Form, Image, Input, List, message } from "antd";

import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import { DataStore, Storage } from "aws-amplify";
import { useTranslation } from "react-i18next";

import { Restaurant, RestaurantCategories } from "../../models";
import { useRestaurantContext } from "../../context/restaurant-context";
import MapComponent from "../../components/map";
import PhotoPicker from "../../PhotoPicker";
import RestaurantCategoriesComponent from "../../components/restaurant-categories";

export type ImagePickData = {
  file: File;
  name: string;
  size: number;
  type: string;
};

type FormValues = {
  restaurantCategories: RestaurantCategories;
};

type Coordinates = {
  lat: number;
  lng: number;
} | null;

export default function Settings() {
  const [logoPreviewImage, setLogoPreviewImage] = useState<string>();
  const [logoData, setLogoData] = useState<ImagePickData>();
  const [teaserPreviewImage, setTeaserPreviewImage] = useState<string>();
  const [teaserData, setTeaserData] = useState<ImagePickData>();

  const { t } = useTranslation();
  const { restaurant, setRestaurant, sub } = useRestaurantContext();

  const {
    ready,
    value: address,
    setValue: setAddress,
    suggestions: { status, data },
    clearSuggestions,
  } = usePlacesAutocomplete();

  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [coordinates, setCoordinates] = useState<Coordinates>(null);

  async function uploadImage(
    filename?: string,
    file?: File
  ): Promise<string | null> {
    if (file === null || !filename) return null;

    try {
      const result = await Storage.put(filename, file, {
        level: "public",
        contentType: "image/*",
      });
      console.log("STORAGE PUT SUCCESS: ", result.key);
      return result.key;
    } catch (err) {
      console.log("STORAGE PUT ERROR: ", err);
      return null;
    }
  }

  useEffect(() => {
    if (restaurant) {
      setName(restaurant.name);
      setAddress(restaurant.address, false);
      setCoordinates({ lat: restaurant.lat, lng: restaurant.lng });
    }
  }, [restaurant, setAddress]);

  const selectLocation = async (address: string) => {
    setAddress(address, false);
    clearSuggestions();

    const results = await getGeocode({ address });

    const { lat, lng } = await getLatLng(results[0]);
    setCoordinates({ lat, lng });
  };

  const onFinish = async (values: FormValues) => {
    if (!restaurant) {
      await createNewRestaurant(values);
    } else {
      await updateNewRestaurant(values, restaurant);
    }
  };

  const createNewRestaurant = async (values: FormValues) => {
    setLoading(true);

    try {
      if (!address || !coordinates) {
        return alert("Missing address or coordinates");
      }

      let logoKey = null;
      let teaserKey = null;
      let image: { logoKey: string | null; teaserKey: string | null } = {
        logoKey: null,
        teaserKey: null,
      };

      if (logoData) {
        logoKey = await uploadImage(logoData.name, logoData.file);
        image.logoKey = logoKey;
      }

      if (teaserData) {
        teaserKey = await uploadImage(teaserData.name, teaserData.file);
        image.teaserKey = teaserKey;
      }

      const newRestaurant = await DataStore.save(
        new Restaurant({
          name: name,
          address: address,
          lat: coordinates.lat,
          lng: coordinates.lng,
          minDeliveryTime: 15,
          maxDeliveryTime: 45,
          adminSub: sub,
          restaurantCategories: values.restaurantCategories,
          image,
        })
      );

      setRestaurant(newRestaurant);
      message.success(t("app.settings.messages.create-success"));
    } catch (error) {
      console.error("ERROR createNewRestaurant", error);
    } finally {
      setTimeout(() => setLoading(false), 500);
    }
  };

  const updateNewRestaurant = async (
    values: FormValues,
    currentRestaurant: Restaurant
  ) => {
    setLoading(true);

    try {
      let logoKey = null;
      let teaserKey = null;
      let image: { logoKey: string | null; teaserKey: string | null } = {
        logoKey: null,
        teaserKey: null,
      };

      if (logoData) {
        logoKey = await uploadImage(logoData.name, logoData.file);
        image.logoKey = logoKey;
      }
      if (teaserData) {
        teaserKey = await uploadImage(teaserData.name, teaserData.file);
        image.teaserKey = teaserKey;
      }

      const updatedRestaurant = await DataStore.save(
        Restaurant.copyOf(currentRestaurant, (updated) => {
          updated.name = name;
          updated.restaurantCategories = values.restaurantCategories;

          updated.image = {
            logoKey: image.logoKey || updated.image?.logoKey || null,
            teaserKey: image.teaserKey || updated.image?.teaserKey || null,
          };

          if (address && coordinates) {
            updated.address = address;
            updated.lat = coordinates.lat;
            updated.lng = coordinates.lng;
          }
        })
      );

      setRestaurant(updatedRestaurant);
      message.success(t("app.settings.messages.update-success"));
    } catch (error) {
      console.error("ERROR updateNewRestaurant", error);
    } finally {
      setTimeout(() => setLoading(false), 500);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.error("onFinishFailed", errorInfo);
  };

  const imageStyle = {
    height: 150,
    width: 150,
  };

  return (
    <Card title={t("app.settings.title")}>
      <Form
        layout={"vertical"}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        disabled={!ready || loading}
      >
        <div
          style={{
            flexDirection: "row",
            display: "flex",
            alignItems: "flex-start",
          }}
        >
          <Form.Item
            style={{ marginRight: "1rem" }}
            label={t("app.settings.image-upload.label")}
            rules={[{ required: true }]}
            required
          >
            <PhotoPicker
              s3Image={restaurant?.image?.logoKey}
              title={t("app.settings.file-upload.label")}
              preview
              previewSrc={logoPreviewImage}
              onLoad={(url) => {
                console.log("onLoad", url);
                setLogoPreviewImage(url as string);
              }}
              onPick={(data) => {
                console.log("onPick", data);
                setLogoData(data);
              }}
              containerStyle={{
                display: "flex",
                flexDirection: "column",
              }}
              previewStyle={imageStyle}
              theme={
                {
                  button: {
                    marginTop: 8,
                    width: imageStyle.width,
                  },
                } as any
              }
            />
          </Form.Item>

          <Form.Item
            label={"Vorschau-Bild"}
            rules={[{ required: true }]}
            required
          >
            <PhotoPicker
              s3Image={restaurant?.image?.teaserKey}
              title={t("app.settings.file-upload.label")}
              preview
              previewSrc={teaserPreviewImage}
              onLoad={(url) => {
                console.log("onLoad", url);
                setTeaserPreviewImage(url as string);
              }}
              onPick={(data) => {
                console.log("onPick", data);
                setTeaserData(data);
              }}
              containerStyle={{
                display: "flex",
                flexDirection: "column",
              }}
              previewStyle={imageStyle}
              theme={
                {
                  button: {
                    marginTop: 8,
                    width: imageStyle.width,
                  },
                } as any
              }
            />
          </Form.Item>
        </div>

        <Form.Item
          label={t("app.settings.label.name")}
          rules={[{ required: true }]}
          required
        >
          <Input
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder={t("app.settings.placeholder.name") || ""}
          />
        </Form.Item>

        <Form.Item
          label={t("app.settings.label.address")}
          rules={[{ required: true }]}
          required
        >
          <Input
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            placeholder={t("app.settings.placeholder.address") || ""}
          />
        </Form.Item>

        <RestaurantCategoriesComponent />

        {status === "OK" && (
          <Card>
            <List
              dataSource={data}
              renderItem={({ place_id, description }) => {
                return (
                  <Button
                    key={place_id}
                    style={{ display: "flex" }}
                    type={"text"}
                    onClick={() => selectLocation(description)}
                  >
                    {description}
                  </Button>
                );
              }}
            />
          </Card>
        )}

        {restaurant && coordinates && (
          <div style={{ marginBottom: 16 }}>
            <MapComponent
              location={{
                address: restaurant.address,
                lat: coordinates.lat,
                lng: coordinates.lng,
              }}
            />
          </div>
        )}

        <Form.Item>
          <Button type={"primary"} htmlType={"submit"} loading={loading}>
            {t("app.settings.button-save")}
          </Button>
        </Form.Item>
      </Form>

      <span>
        {coordinates?.lat} - {coordinates?.lng}
      </span>
    </Card>
  );
}
