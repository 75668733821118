import "@aws-amplify/ui-react/styles.css";

import React, { useMemo } from "react";
import { Card, ConfigProvider, Image, Layout, Spin } from "antd";

import { useJsApiLoader } from "@react-google-maps/api";
import { UseLoadScriptOptions } from "@react-google-maps/api/dist/useLoadScript";

import { withAuthenticator } from "@aws-amplify/ui-react";

import AppRoutes from "./components/app-routes";
import SideMenu from "./components/side-menu";
import {
  RestaurantContextProvider,
  useRestaurantContext,
} from "./context/restaurant-context";
import LoadingOverlay from "./components/loading-overlay";
import S3Image from "./S3Image";

const { Sider, Footer, Content } = Layout;

function AppWithProviders() {
  return (
    <RestaurantContextProvider>
      <App />
    </RestaurantContextProvider>
  );
}

const theme = {
  token: {
    colorPrimary: "#65C2C4", // #1E2746 #65C2C4
    colorPrimaryHover: "red",
  },
};

function App() {
  const context = useRestaurantContext();

  /**
   * SETUP GOOGLE MAPS & PLACES AUTO COMPLETE
   */
  const { isLoaded } = useJsApiLoader(
    useMemo<UseLoadScriptOptions>(
      () => ({
        id: "google-map-script",
        googleMapsApiKey: process.env.REACT_APP_PLACE_API_KEY!,
        libraries: ["places"],
      }),
      []
    )
  );

  if (!isLoaded) {
    return <LoadingOverlay />;
  }

  return (
    <ConfigProvider theme={theme}>
      <Layout>
        <Sider style={styles.sider}>
          <div>
            {context.loading ? (
              <Spin
                style={{
                  paddingTop: 32,
                  paddingBottom: 32,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              />
            ) : context.restaurant?.image?.logoKey ? (
              <S3Image
                alt="logo"
                imgKey={context.restaurant?.image?.logoKey}
                style={{ width: "100%" }}
                //preview={false}
              />
            ) : (
              <Image
                alt="logo-static"
                style={{ padding: 24 }}
                src={require("../src/assets/logo.png")}
                preview={false}
              />
            )}
          </div>

          <SideMenu />
        </Sider>

        <Layout>
          <Content
            style={{
              margin: 16,
              padding: 24,
              minHeight: 280,
            }}
          >
            <AppRoutes />
          </Content>

          <Footer style={{ textAlign: "center" }}>
            Tasty Delivery Restaurant Dashboard ©2022
          </Footer>
        </Layout>
      </Layout>
    </ConfigProvider>
  );
}

const styles = {
  sider: {
    height: "100vh",
    backgroundColor: "white",
  },
};

export default withAuthenticator(AppWithProviders);
