import React from "react";
import { Route, Routes } from "react-router-dom";

import CreateMenuItem from "../../screens/create-menu-item";
import DetailedOrder from "../../screens/detailed-order";
import OrderHistory from "../../screens/order-history";
import Orders from "../../screens/orders";
import RestaurantMenu from "../../screens/restaurant-menu";
import Settings from "../../screens/settings";
import { useRestaurantContext } from "../../context/restaurant-context";
import LoadingOverlay from "../loading-overlay";

export default function AppRoutes() {
  const { loading } = useRestaurantContext();

  if (loading) return <LoadingOverlay />;

  return (
    <Routes>
      <Route path={"/"} element={<Orders />} />
      <Route path={"order/:id"} element={<DetailedOrder />} />
      <Route path={"menu"} element={<RestaurantMenu />} />
      <Route path={"menu/create"} element={<CreateMenuItem />} />
      <Route path={"menu/create"} element={<CreateMenuItem />} />
      <Route path={"order-history"} element={<OrderHistory />} />
      <Route path={"settings"} element={<Settings />} />
    </Routes>
  );
}
