import React, { useEffect, useState } from "react";
import { Form, Select } from "antd";
import type { SelectProps } from "antd";
import { useTranslation } from "react-i18next";

import { RestaurantCategories } from "../../models";
import { useRestaurantContext } from "../../context/restaurant-context";

const RestaurantCategoriesComponent: React.FC = () => {
  const { t } = useTranslation();
  const [options, setOptions] = useState<SelectProps["options"]>([]);
  const { restaurant } = useRestaurantContext();

  useEffect(() => {
    function enumToArray(): SelectProps["options"] {
      return Object.keys(RestaurantCategories).map((key) => ({
        label:
          // @ts-ignore
          RestaurantCategories[key].charAt(0) +
          // @ts-ignore
          RestaurantCategories[key].substring(1).toLowerCase(),
        value: key,
      }));
    }

    setOptions(enumToArray());
  }, []);

  const handleChange = (value: string) => {
    console.log(`selected ${value}`);
  };

  return (
    <Form.Item
      label={t("app.settings.restaurant-categories")}
      name={"restaurantCategories"}
      rules={[{ required: true }]}
      required
      initialValue={restaurant?.restaurantCategories}
    >
      <Select
        mode="tags"
        style={{ width: "100%" }}
        placeholder="Restaurant Tags"
        onChange={handleChange}
        options={options}
      />
    </Form.Item>
  );
};

export default RestaurantCategoriesComponent;
