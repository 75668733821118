// Create a context
import React, { createContext, ReactElement, useEffect, useState } from "react";
import { Restaurant } from "../models";
import { Auth, DataStore } from "aws-amplify";
import { CognitoUser } from "amazon-cognito-identity-js";

export interface IRestaurantContext {
  restaurant: Restaurant | null;
  setRestaurant: (restaurant: Restaurant | null) => void;
  sub?: string;
  loading: boolean;
  authUser: CognitoUser | any;
}

const RestaurantContext: React.Context<{}> = createContext<
  IRestaurantContext | {}
>({});

const RestaurantContextProvider = ({
  children,
}: {
  children: ReactElement;
}) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [authUser, setAuthUser] = useState<CognitoUser | any>();
  const [restaurant, setRestaurant] = useState<Restaurant | null>(null);

  const sub = authUser?.attributes?.sub;

  useEffect(() => {
    Auth.currentAuthenticatedUser({ bypassCache: true }).then(setAuthUser);
  }, []);

  useEffect(() => {
    if (!sub) {
      return;
    }

    setLoading(true);

    // fetch Restaurant and filter by adminSub
    DataStore.query(Restaurant, (r) => r.adminSub.eq(sub))
      .then((restaurants) => {
        setRestaurant(restaurants[0]);
      })
      .finally(() => {
        setTimeout(() => setLoading(false), 500);
      });
  }, [sub]);

  return (
    <RestaurantContext.Provider
      value={{
        restaurant,
        setRestaurant,
        sub,
        loading,
        authUser,
      }}
    >
      {children}
    </RestaurantContext.Provider>
  );
};

export const useRestaurantContext = (): IRestaurantContext =>
  React.useContext(RestaurantContext) as IRestaurantContext;

export { RestaurantContext, RestaurantContextProvider };
